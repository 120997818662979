var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "domain-transfer-foa" } },
    [
      !_vm.loading && _vm.domainTransferRequest
        ? _c(
            "div",
            [
              _vm.foaAccepted
                ? _c("div", [
                    _c("h5", [
                      _vm._v(
                        "Your domain transfer request authorization has been accepted."
                      ),
                    ]),
                    _c("p", { staticClass: "pt-4" }, [
                      _vm._v('Click "Continue" to check the transfer status.'),
                    ]),
                  ])
                : _vm.transferRequestExpired
                ? _c("div", [
                    _c("h5", [
                      _vm._v("This domain transfer request has expired."),
                    ]),
                    _c("p", { staticClass: "pt-4" }, [
                      _vm._v("The domain transfer request for "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.transferRequest.domain_name)),
                      ]),
                      _vm._v(" has exceeded the 24 hour response time."),
                    ]),
                    _c("p", [
                      _vm._v(
                        'If you still wish to transfer this domain you may do so after clicking "Continue".'
                      ),
                    ]),
                  ])
                : _vm.foaPendingAcceptance
                ? _c(
                    "div",
                    [
                      _c("p", [_vm._v("Attention: " + _vm._s(_vm.clientName))]),
                      _c("p", [
                        _vm._v(
                          "Re: Transfer of " +
                            _vm._s(_vm.domainTransferRequest.domain_name)
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Corporate Filings LLC has received a request from " +
                            _vm._s(_vm.clientName) +
                            " on " +
                            _vm._s(
                              _vm.formatDate(
                                _vm.domainTransferRequest.created_at
                              )
                            ) +
                            " for us to become the new registrar of record."
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "You have received this message because you are listed as the Registered Name Holder for this domain name."
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Please read the following important information about transferring your domain name:"
                        ),
                      ]),
                      _vm._m(0),
                      _c("p", [
                        _vm._v(
                          "Once you have entered into the Agreement, the transfer will take place within five (5) calendar days unless the current registrar of record denies the request."
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Once a transfer takes place, you will not be able to transfer to another registrar for 60 days, apart from a transfer back to the original registrar, in cases where both registrars so agree or where a decision in the dispute resolution process so directs."
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "If you WISH TO PROCEED with the transfer, you must respond to this message via the following method (note if you do not respond by " +
                            _vm._s(_vm.respondBy) +
                            ", " +
                            _vm._s(_vm.domainTransferRequest.domain_name) +
                            " will not be transferred to us)."
                        ),
                      ]),
                      _c("p", { staticClass: "mt-5" }, [
                        _vm._v('Please click "Agree" to confirm.'),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "primary-button",
                              attrs: {
                                variant: "primary",
                                "aria-label": "Agree button",
                                disabled: _vm.loading,
                              },
                              on: { click: _vm.acceptFoa },
                            },
                            [
                              _c(
                                "b-overlay",
                                {
                                  attrs: {
                                    show: _vm.loading,
                                    rounded: "sm",
                                    opacity: 0,
                                    variant: "black",
                                  },
                                },
                                [_vm._v("\n          Agree\n        ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "text--error mt-4" }, [
                        _vm._v(_vm._s(_vm.errorMessage)),
                      ]),
                      _c("p", { staticClass: "mt-5" }, [
                        _vm._v(
                          "If you DO NOT WANT the transfer to proceed, then don't respond to this message."
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "If you have any questions about this process, please contact us."
                        ),
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.loading,
                            variant: "outline-danger",
                            "aria-label": "FOA close button",
                          },
                          on: { click: _vm.returnHome },
                        },
                        [_vm._v("\n      Close\n    ")]
                      ),
                    ],
                    1
                  )
                : _c("div", [
                    _c("h5", [
                      _vm._v("This domain transfer request is not valid."),
                    ]),
                  ]),
              _vm.showContinueButton
                ? _c(
                    "b-button",
                    {
                      staticClass: "primary-button mt-4",
                      attrs: {
                        variant: "primary",
                        "aria-label": "foa return button",
                      },
                      on: { click: _vm.returnHome },
                    },
                    [_vm._v("\n      Continue\n    ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm.loading
        ? _c("ct-centered-spinner")
        : !_vm.loading
        ? _c(
            "div",
            [
              _c("h1", [_vm._v(" Transfer Request Not Found! ")]),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    "aria-label": "FOA Return Button",
                  },
                  on: { click: _vm.returnHome },
                },
                [_vm._v("\n      Return Home\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        "You must agree to enter into a new Registration Agreement with us. You can review the full terms and conditions of the Agreement here: "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.corporatefilingsllc.com/terms-of-service",
            target: "#",
          },
        },
        [_vm._v("Terms and Conditions")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }