<template>
  <div id="domain-transfer-foa">
    <div v-if="!loading && domainTransferRequest">
      <div v-if="foaAccepted">
      <h5>Your domain transfer request authorization has been accepted.</h5>
      <p class="pt-4">Click "Continue" to check the transfer status.</p>
    </div>
      <div v-else-if="transferRequestExpired">
      <h5>This domain transfer request has expired.</h5>
      <p class="pt-4">The domain transfer request for <strong>{{ transferRequest.domain_name }}</strong> has exceeded the 24 hour response time.</p>
      <p>If you still wish to transfer this domain you may do so after clicking "Continue".</p>
    </div>
      <div v-else-if="foaPendingAcceptance">
      <p>Attention: {{ clientName }}</p>
      <p>Re: Transfer of {{ domainTransferRequest.domain_name }}</p>

      <p>Corporate Filings LLC has received a request from {{ clientName }} on {{ formatDate(domainTransferRequest.created_at) }} for us to become the new registrar of record.</p>

      <p>You have received this message because you are listed as the Registered Name Holder for this domain name.</p>

      <p>Please read the following important information about transferring your domain name:</p>

      <p>You must agree to enter into a new Registration Agreement with us. You can review the full terms and conditions of the Agreement here: <a href="https://www.corporatefilingsllc.com/terms-of-service" target="#">Terms and Conditions</a></p>
      <p>Once you have entered into the Agreement, the transfer will take place within five (5) calendar days unless the current registrar of record denies the request.</p>
      <p>Once a transfer takes place, you will not be able to transfer to another registrar for 60 days, apart from a transfer back to the original registrar, in cases where both registrars so agree or where a decision in the dispute resolution process so directs.</p>

      <p>If you WISH TO PROCEED with the transfer, you must respond to this message via the following method (note if you do not respond by {{ respondBy }}, {{ domainTransferRequest.domain_name }} will not be transferred to us).</p>
      <p class="mt-5">Please click "Agree" to confirm.</p>
      <div>
        <b-button
          class="primary-button"
          variant="primary"
          aria-label="Agree button"
          :disabled="loading"
          @click="acceptFoa"
        >
          <b-overlay :show="loading" rounded="sm" :opacity="0" variant="black">
            Agree
          </b-overlay>
        </b-button>
      </div>
      <p class="text--error mt-4">{{ errorMessage }}</p>
      <p class="mt-5">If you DO NOT WANT the transfer to proceed, then don't respond to this message.</p>
      <p>If you have any questions about this process, please contact us.</p>
      <b-button
        :disabled="loading"
        variant="outline-danger"
        aria-label="FOA close button"
        @click="returnHome"
      >
        Close
      </b-button>
    </div>
      <div v-else>
        <h5>This domain transfer request is not valid.</h5>
      </div>
      <b-button
        v-if="showContinueButton"
        class="primary-button mt-4"
        variant="primary"
        :aria-label="'foa return button'"
        @click="returnHome"
      >
        Continue
      </b-button>
    </div>
    <ct-centered-spinner v-else-if="loading" />
    <div v-else-if="!loading">
      <h1> Transfer Request Not Found! </h1>
      <b-button
        variant="primary"
        aria-label="FOA Return Button"
        @click="returnHome"
      >
        Return Home
      </b-button>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {makeToastMixin} from "@/mixins/makeToastMixin";
import {mapActions, mapGetters} from "vuex";
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { formatDateTimeLong } from '@/common/modules/formatters'

export default defineComponent({
  name: "FoaAuthorization.vue",
  components: { CtCenteredSpinner },
  mixins: [
    makeToastMixin,
  ],
  data() {
    return {
      loading: false,
      errorMessage: null,
      domainTransferRequest: null,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domainTransferRequests',
    ]),
    ...mapGetters('account', [
      'account',
    ]),
    clientName() {
      return `${this.account.first_name} ${this.account.last_name}`
    },
    respondBy() {
      const foaCanSendTime = new Date(this.domainTransferRequest.foa_notification_sent)
      foaCanSendTime.setHours(foaCanSendTime.getHours() + 24)
      return this.formatDate(foaCanSendTime.toISOString())
    },
    foaAccepted() {
      return this.domainTransferRequest?.foa_accepted
    },
    transferRequestExpired() {
      return this.domainTransferRequest?.status === 'expired'
    },
    foaPendingAcceptance() {
      return this.domainTransferRequest?.status === 'started'
    },
    showContinueButton() {
      return !this.foaPendingAcceptance
    },
  },
  async created() {
    this.loading = true
    this.foaToken = this.$route.params.token
    const companyId = this.$route.params.company_id
    await this.fetchDomainTransferRequests(companyId)
    this.domainTransferRequest = this.domainTransferRequests.find(dtr => dtr.foa_token === this.foaToken)
    this.loading = false
  },
  methods: {
    ...mapActions('domains', [
      'acceptDomainTransferRequestFoa',
      'fetchDomainTransferRequests',
    ]),
    formatDate(rawDate) {
      return formatDateTimeLong(rawDate)
    },
    async acceptFoa() {
      this.loading = true
      try {
        const result = await this.acceptDomainTransferRequestFoa({ id: this.domainTransferRequest.id, foa_token: this.foaToken })
        if (result.data?.status === 200) {
          await this.bus.$emit('log-domain-interaction', { name: 'domain-transfer-request' })
          this.successToast('Success', 'Transfer authorization accepted!')
          this.returnHome()
        } else {
          this.errorMessage = result.data?.response?.error
        }
      } catch {
        this.errorToast('Submission Error', 'There was an error submitting your request.')
      } finally {
        this.loading = false
      }
    },
    returnHome() {
      this.$router.push('/stageline-v2')
    },
  },

})
</script>

<style scoped lang="scss">
#domain-transfer-foa {
  .text--error {
    color: #F45151;
  }

  .primary-button {
    min-width: 225px;
    min-height: 50px;
  }
}

</style>
